<template>
    <div>
        <v-card>
            <v-card-title>
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    Upload Files
                </h2>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="px-5">
                <div :class="{ 'd-flex': files.length == 0 }">
                    <div
                        v-if="files.length == 0"
                        class="dropZone"
                        @dragenter="dragging = true"
                        @dragleave="dragging = false"
                    >
                        <div class="dropZone-info" @drag="onFilePicked">
                            <v-btn
                                large
                                icon
                                @click="$refs.files.click()"
                                class="mb-1 pa-11"
                            >
                                <v-icon size="80">mdi-folder-upload</v-icon>
                            </v-btn>
                            <h3>Drag and drop folders here</h3>
                        </div>
                        <input
                            ref="files"
                            type="file"
                            name="folder"
                            webkitdirectory
                            multiple
                            @change="onFilePicked"
                        />
                    </div>
                    <div
                        v-if="files.length == 0"
                        class="dropZone"
                        @dragenter="dragging = true"
                        @dragleave="dragging = false"
                    >
                        <div class="dropZone-info" @drag="onFileCompressPicked">
                            <v-btn
                                large
                                icon
                                @click="$refs.files.click()"
                                class="mb-1 pa-11"
                            >
                                <v-icon size="80">mdi-folder-upload</v-icon>
                            </v-btn>
                            <h3>Drag and drop .ZIP files here</h3>
                        </div>
                        <input
                            ref="files"
                            type="file"
                            name="archive"
                            accept=".zip"
                            @change="onFileCompressPicked"
                        />
                    </div>
                    <div v-else class="mt-6 mb-3">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            hide-details
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:[`item.dxf`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-checkbox
                                        class="my-0 ml-1"
                                        v-model="item.dxf"
                                        readonly
                                        hide-details
                                    />
                                </div>
                            </template>
                            <template v-slot:[`item.pdf`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-checkbox
                                        class="my-0 ml-1"
                                        v-model="item.pdf"
                                        readonly
                                        hide-details
                                    />
                                </div>
                            </template>
                            <template v-slot:[`item.stp`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-checkbox
                                        class="my-0 ml-1"
                                        v-model="item.stp"
                                        readonly
                                        hide-details
                                    />
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    class="mb-4 mt-n4"
                    @click="saveFiles"
                    :disabled="files.length == 0"
                    :loading="loading"
                >
                    SAVE FILES
                </v-btn>
            </v-card-actions>
            <!--progress dialog-->
            <v-dialog
                v-model="progressDialog"
                persistent
                :retain-focus="false"
                max-width="400px"
            >
                <v-card>
                    <v-card-text>
                        <div class="py-4">
                            <h3>
                                UPLOADING FILES
                                {{
                                    parseInt(
                                        accumulated /
                                            parseInt(100 / numberFiles)
                                    ) || 0
                                }}
                                of {{ numberFiles }}
                            </h3>
                        </div>
                        <v-progress-linear v-model="accumulated" height="25">
                            <strong>{{ Math.ceil(accumulated) }}%</strong>
                        </v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import { saveFile } from '@/services/storage/saveFile.js'
import JSZip from 'jszip'
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'UploadFiles',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        numberFiles: 0,
        progressDialog: false,
        accumulated: 0,
        extensions: 'pdf,dxf,stp',
        loading: false,
        files: [],
        file: '',
        dragging: false,
        headers: [
            {
                text: 'PART NUMBER',
                value: 'name',
                sortable: false,
                align: 'center',
            },
            {
                text: 'DXF',
                value: 'dxf',
                sortable: false,
                align: 'center',
            },
            {
                text: 'PDF',
                value: 'pdf',
                sortable: false,
                align: 'center',
            },
            {
                text: 'STP',
                value: 'stp',
                sortable: false,
                align: 'center',
            },
        ],
        items: [],
        companyId: JSON.parse(localStorage.getItem('company')),
    }),
    computed: {
        extension() {
            return this.file ? this.file.name.split('.').pop() : ''
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getMimeType(extension) {
            return extension == 'dxf'
                ? 'application/dxf'
                : extension == 'stp'
                ? 'application/STEP'
                : extension == 'pdf'
                ? 'application/pdf'
                : ''
        },
        async onFileCompressPicked(event) {
            const fileInput = event.target
            const zip = await JSZip.loadAsync(fileInput.files[0])
            for (let zobj of Object.values(zip.files)) {
                if (zobj.dir) continue
                const zblob = await zobj.async('blob')
                const filename = zobj.name.split('/').pop()
                const extension = filename.split('.').pop()
                const zfile = new File([zblob], filename, {
                    type: this.getMimeType(extension),
                })
                this.files = [...this.files, zfile]
            }
            const partNumbers = {}
            //verify extensions
            this.files.forEach(file => {
                let arr = file.name.split('.')
                let name = arr[0]
                let extension = arr.pop()
                if (partNumbers[name]) {
                    if (this.extensions.includes(extension)) {
                        partNumbers[name][extension] = true
                    }
                } else {
                    if (this.extensions.includes(extension)) {
                        partNumbers[name] = {
                            [extension]: true,
                        }
                    }
                }
            })
            Object.keys(partNumbers).forEach(key => {
                this.items.push({
                    name: key,
                    ...partNumbers[key],
                })
            })
        },
        onFilePicked(event) {
            this.files = [...event.target.files]
            const partNumbers = {}
            // verify extensions
            this.files.forEach(file => {
                let arr = file.name.split('.')
                let name = arr[0]
                let extension = arr.pop()
                if ('pdfdxfstp'.includes(extension.toLowerCase())) {
                    if (partNumbers[name]) {
                        if (this.extensions.includes(extension)) {
                            partNumbers[name][extension] = true
                        }
                    } else {
                        if (this.extensions.includes(extension)) {
                            partNumbers[name] = {
                                [extension]: true,
                            }
                        }
                    }
                }
            })
            Object.keys(partNumbers).forEach(key => {
                this.items.push({
                    name: key,
                    ...partNumbers[key],
                })
            })
        },
        async saveFiles() {
            try {
                this.loading = true
                const blobs = {}
                if (!this.workOrder.items || this.workOrder.items.length == 0) {
                    throw new Error('No work order items')
                }
                // validate files
                for (let file of this.files) {
                    const arr = file.name.split('.')
                    const fileName = arr[0]
                    let extension = arr.pop()
                    if ('pdfdxfstp'.includes(extension.toLowerCase())) {
                        const item = this.workOrder.items.find(
                            item =>
                                item.partNumber.toLowerCase() ==
                                fileName.toLowerCase()
                        )
                        if (!item) {
                            throw new Error(
                                `The ${fileName} does not belong to the items`
                            )
                        } else if (!this.extensions.includes(extension)) {
                            throw new Error(
                                `The file extension ${extension} is not allowed`
                            )
                        }
                        this.numberFiles += 1
                    }
                }
                // configure load
                this.progressDialog = true
                const percentage = parseInt(100 / this.numberFiles)
                // save files
                for (const file of this.files) {
                    const arr = file.name.split('.')
                    let extension = arr.pop()
                    if ('pdfdxfstp'.includes(extension.toLowerCase())) {
                        await saveFile(
                            file,
                            `${this.companyId}/workOrders/${this.workOrder.id}/items`
                        )
                        const name = file.name.split('.')[0]
                        const extension = file.name.split('.')[1]
                        if (!blobs[name]) {
                            blobs[name] = {}
                        }
                        blobs[name][extension] = URL.createObjectURL(file)
                        this.accumulated += percentage
                    }
                }
                // update items
                const newItems = []
                for (const code of Object.keys(blobs)) {
                    const item = this.workOrder.items.find(
                        item =>
                            item.partNumber.toLowerCase() == code.toLowerCase()
                    )
                    newItems.push({
                        itemId: item.id,
                        files: Object.keys(blobs[code]).map(
                            ext => `${code}.${ext}`
                        ),
                    })
                }
                await API.addFilesToItems({
                    workOrderId: this.workOrder.id,
                    items: newItems,
                })
                this.$emit('addFiles', newItems)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.progressDialog = false
                this.numberFiles = 0
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style>
.dropZone {
    background-color: #f5f5f5;
    width: 100%;
    height: 313px;
    margin-top: 24px;
    margin-bottom: 12px;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    border: 2px dashed #eee;
}

.dropZone:hover {
    border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
    color: #1975a0;
}

.dropZone-info {
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropZone-title {
    color: #787878;
}

.dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropZone-over {
    background: #5c5c5c;
    opacity: 0.8;
}
</style>
